import { useParams, navigate } from '@reach/router'
import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Divider } from '@material-ui/core'
import { Grid } from '@mui/material'

import useRolePermission from '_hooks/use-role-permission'
import useToggle from '_hooks/use-toggle'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import { getBudgetByIdSelector, getStepStatusLogSelector } from '_modules/budget/selectors'
import { getHistoryLogsSelector } from '_modules/history-logs/selectors'
import Button from '_components/button'
import ModalDialog, { WARNING_MODAL } from '_components/modal/modal-dialog'
import { ROUTES } from '_utils/constants'
import { STEP_STATUS } from '_utils/constants/service-order'
import { cancelReasonsSelector } from '_modules/utils/selectors'
import { useConfirm } from '@refera/ui-web'
import ConfirmRemoveDialog from '_/components/dialogs/ConfirmRemoveDialog'
import { useToast } from '_/hooks/use-toast'

import { cancelApproveBudget, updateBudget, getStepStatusLog } from '_modules/budget/actions'

import RefuseBudgetDialog from '../unrefuse-budget-dialog'
import SaveChangesDialog from '../save-changes-dialog'
import ReviseBudgetDialog from '../revise-budget-dialog'
import ReturnToProviderDialog from '../return-to-provider-dialog'
import RejectBudgetDrawer from '../reject-budget-drawer'
import CancelSentEmailRevisedBudget from '../cancel-sent-email-revised-budget'
import CancelRevisedBudgetDialog from '../cancel-revised-budget-dialog'
import CancelApproveModal from '../cancel-approve-modal'
import UnavailableActionBudgetModal from '../unavailable-budget-dialog'
import useIsCurrentStepStatusCanceled from '_hooks/use-is-current-step-status-canceled'

import useStyles from './styles'
import { useDataSanatize } from './data-sanatize'
import IntermediaryRefuseModal from './intermediary-refuse-modal'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import { getServiceOrder } from '_/modules/service-orders/actions'
import { getHistoryLogs } from '_/modules/history-logs/actions'
import { formatErrorMessage } from '_/views/finance/utils/FormatErrorMessage'

const unavailableBudgetText = 'Este não é o Orçamento vigente do chamado em questão.'

// TODO: Move this component to the 'components' folder
const BudgetButtons = ({ isEdit, setSavingBudget, isSavingLoading }) => {
  const styles = useStyles()
  const { budgetId, serviceOrderId } = useParams()

  const dispatch = useDispatch()
  const [isRevised, setIsRevised] = useState(false)

  const [isUnrefuseBudgetDialogOpen, onToggleUnrefuseBudgetDialogOpen] = useToggle()
  const [isSaveChangesModalOpen, onToggleSaveChangesDialog] = useToggle()
  const [isReviseBudgetDialogOpen, setIsReviseBudgetDialogOpen] = useState(false)
  const [isReturnToProviderDialogOpen, onToggleReturnToProviderDialog] = useToggle()
  const [isRejectBudgetDrawerOpen, onToggleRejectBudgetDrawerOpen] = useToggle()
  const [isCancelSentEmailReviseBudgetOpen, onToggleCancelSentEmailReviseBudgetOpen] = useToggle()
  const [isCancelReviseBudgetDialogOpen, onToggleCancelReviseBudgetDialog] = useToggle()
  const [isCancelApproveBudgetDrawerOpen, onToggleCancelApproveBudgetDrawerOpen] = useToggle()
  const [modalDialog, setModalDialog] = useState({ isOpen: false, subTitle: '' })
  const [isUnavailableBudgetOpen, onToggleIsUnavailableBudgetOpen] = useToggle()
  const [isIntermediaryRefuseModalOpen, onToggleIntermediaryRefuseModalOpen] = useToggle()

  const cancelReasons = useSelector(cancelReasonsSelector)
  const stepStatusLog = useSelector(getStepStatusLogSelector)
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const budget = useSelector(getBudgetByIdSelector(budgetId))
  const historyLogs = useSelector(getHistoryLogsSelector)
  const isActive = useMemo(() => Boolean(serviceOrder?.isActive), [serviceOrder?.isActive])
  const isCanceled = useIsCurrentStepStatusCanceled()
  const { checkUserPermission, checkIfUserDoesNotPermission, isIntermediary, isAdmin, isSAAS } =
    useRolePermission()

  const {
    canApproveBudget,
    canApproveInnerBudget,
    canCancelApproval,
    canCancelReview,
    canReproveBudget,
    canReproveInnerBudget,
    canReturnBudget,
    canReviewBudget,
    canIntermediaryReviewBudget,
    canIntermediaryRefuseBudget,
  } = useDataSanatize()

  const hasSentEmail = useMemo(
    () => historyLogs?.filter(item => item.logType === 'budget_person_paying_email_sent'),
    [historyLogs]
  )

  const refetchServiceOrder = useCallback(() => {
    dispatch(getServiceOrder(serviceOrderId))
  }, [dispatch, serviceOrderId])

  const hasAvailableBudget = useMemo(() => {
    if (serviceOrder.budget !== undefined) {
      return serviceOrder?.budget?.some(
        item =>
          item !== undefined &&
          item.get('id') === Number(budgetId) &&
          isRevised &&
          !item.get('isOriginal') &&
          !item.get('refused') &&
          !item.get('approved')
      )
    }
    return false
  }, [budgetId, serviceOrder?.budget, isRevised])

  const hasAvailableInnerBudget = useMemo(() => {
    if (serviceOrder.budget !== undefined) {
      return serviceOrder?.budget?.some(
        item =>
          item !== undefined &&
          item.get('id') === Number(budgetId) &&
          !item.get('isOriginal') &&
          !item.get('refused') &&
          !item.get('approved')
      )
    }
    return false
  }, [budgetId, serviceOrder?.budget])

  const hasAnotherAvailableBudget = useMemo(
    () =>
      serviceOrder?.budget?.some(
        item =>
          item !== undefined &&
          !item.get('isOriginal') &&
          item.get('id') !== Number(budgetId) &&
          !item.get('refused') &&
          !item.get('approved')
      ),
    [budgetId, serviceOrder?.budget]
  )

  const { isConfirmed } = useConfirm()
  const { showToast } = useToast()
  const [dialogInfo, setDialogInfo] = useState('')

  const onSuccess = useCallback(() => {
    dispatch(getHistoryLogs(serviceOrderId))
    dispatch(getServiceOrder(serviceOrderId))
    showToast({ type: 'success' })
    setIsRevised(true)
  }, [showToast])

  const handleMarkAsRevisedClick = useCallback(async () => {
    if (isSAAS) {
      setSavingBudget(prevState => ({
        ...prevState,
        updateBudgetItem: true,
      }))
      await dispatch(updateBudget(serviceOrderId, budgetId, { isRevised: true }))
        .then(() => {
          onSuccess()
        })
        .catch(error => {
          showToast({ type: 'error', message: formatErrorMessage(error) })
        })
        .finally(() =>
          setSavingBudget(prevState => ({
            ...prevState,
            updateBudgetItem: false,
          }))
        )
      return
    }

    if (isEdit) {
      onToggleSaveChangesDialog()
      return
    }

    setIsReviseBudgetDialogOpen(true)
  }, [isEdit, onToggleSaveChangesDialog, budgetId, dispatch, serviceOrderId])

  const handleMarkAsRevisedByIntermediaryClick = useCallback(async () => {
    setDialogInfo({
      message: isAdmin
        ? 'Esta ação é exclusiva dos colaboradores da Intermediária. Você tem certeza que deseja executar esta ação no lugar dela?'
        : 'Você tem certeza que deseja executar esta ação?',
    })

    const hasRightStatus = serviceOrder?.stepStatus === 'intermediary_review_budget'
    const confirmed = await isConfirmed()

    if (confirmed) {
      if (!hasRightStatus) {
        showToast({
          type: 'error',
          message:
            'Este registro foi alterado por outro usuário e impossibilitou tal ação. Tente fechar e reabrir esta tela.',
        })
        return
      }

      if (isAdmin || isSAAS) {
        if (isEdit) {
          onToggleSaveChangesDialog()
          return
        }

        setIsReviseBudgetDialogOpen(true)
      } else {
        dispatch(
          updateBudget(serviceOrderId, budgetId, {
            isRevised: true,
            wasBudgetNegotiated: false,
            isServiceCataloged: false,
          })
        ).then(refetchServiceOrder)
      }
    }
  }, [isEdit, onToggleSaveChangesDialog, refetchServiceOrder])

  const handleReturnToProviderClick = useCallback(() => {
    if (isEdit) {
      onToggleSaveChangesDialog()
      return
    }

    onToggleReturnToProviderDialog()
  }, [isEdit, onToggleReturnToProviderDialog, onToggleSaveChangesDialog])

  const handleCancelRevisedClick = useCallback(async () => {
    if (
      isIntermediary &&
      checkIfUserDoesNotPermission(
        PERMISSIONS.VALIDATE_INVALIDATE_BUDGET_CANCEL_ACTION,
        PERMISSIONS_ACTIONS.ADD
      )
    ) {
      return
    }

    if (
      (isAdmin || isSAAS) &&
      checkIfUserDoesNotPermission(
        PERMISSIONS.REVIEW_RETURN_BUDGET_CANCEL_ACTION,
        PERMISSIONS_ACTIONS.ADD
      )
    ) {
      return
    }

    setDialogInfo({
      message: 'A revisão foi feita pela intermediária. Você tem certeza que deseja cancelá-la?',
    })

    const isIntermediaryReview = stepStatusLog[1]?.stepStatus === 'intermediary_review_budget'

    if (!isAdmin && !isIntermediaryReview && !isSAAS) {
      setModalDialog({
        isOpen: true,
        subTitle: 'Esta ação é exclusiva dos colaboradores da Refera.',
        type: WARNING_MODAL,
      })
      return
    }

    if ((isAdmin || isSAAS) && isIntermediaryReview) {
      const confirmed = await isConfirmed()

      if (confirmed) {
        if (isEdit) {
          onToggleSaveChangesDialog()
          return
        }
        if (hasSentEmail) {
          onToggleCancelSentEmailReviseBudgetOpen()
          return
        }
        onToggleCancelReviseBudgetDialog()
        return
      }

      return
    }

    if (isEdit) {
      onToggleSaveChangesDialog()
      return
    }

    if (hasSentEmail) {
      onToggleCancelSentEmailReviseBudgetOpen()
      return
    }

    onToggleCancelReviseBudgetDialog()
  }, [
    hasSentEmail,
    isEdit,
    isAdmin,
    isSAAS,
    stepStatusLog,
    onToggleCancelReviseBudgetDialog,
    onToggleCancelSentEmailReviseBudgetOpen,
    onToggleSaveChangesDialog,
  ])

  const handleServiceOrderCanceled = useCallback(() => {
    setModalDialog({
      isOpen: true,
      subTitle: `Este chamado encontra-se cancelado. Para realizar ações em orçamentos, reative o chamado.`,
      type: WARNING_MODAL,
    })
  }, [])

  const handleCancelApproval = useCallback(() => {
    const stepStatus = serviceOrder?.get('stepStatus')

    switch (stepStatus) {
      case STEP_STATUS.SERVICE_FINISHED:
        setModalDialog({
          isOpen: true,
          subTitle: `Não é possível cancelar a aprovação de um orçamento com um serviço finalizado.${' '}
          Peça para o prestador finalizar o chamado, reprove a finalização e só então repita esta operação.`,
          type: WARNING_MODAL,
        })
        break
      case STEP_STATUS.WAITING_FINALIZATION_APPROVAL:
      case STEP_STATUS.SERVICE_ORDER_FINISHED:
        setModalDialog({
          isOpen: true,
          subTitle: `Não é possível cancelar a aprovação de um chamado já finalizado.${' '}
          Reprove a finalização do serviço realizado pelo prestador e repita esta operação.`,
          type: WARNING_MODAL,
        })
        break
      default:
        onToggleCancelApproveBudgetDrawerOpen()
        break
    }
  }, [onToggleCancelApproveBudgetDrawerOpen])

  const handleApproveBudgetClick = useCallback(() => {
    if (hasAvailableBudget) {
      navigate(
        `${ROUTES.SERVICE_ORDER}/${serviceOrderId}/orcamento/${budgetId}/dados-pagador/aprovar`
      )
      return
    }

    onToggleIsUnavailableBudgetOpen()
  }, [hasAvailableBudget, onToggleIsUnavailableBudgetOpen, serviceOrderId, budgetId])

  const handleApproveInnerBudgetClick = useCallback(() => {
    if (hasAvailableInnerBudget) {
      navigate(
        `${ROUTES.SERVICE_ORDER}/${serviceOrderId}/orcamento/${budgetId}/dados-pagador/aprovar`
      )
      return
    }

    onToggleIsUnavailableBudgetOpen()
  }, [dispatch, serviceOrderId, budgetId, onToggleIsUnavailableBudgetOpen])

  useEffect(() => {
    const isBudgetReview = serviceOrder?.stepStatus === 'review_budget'

    isBudgetReview && isIntermediary
      ? navigate(`${ROUTES.SERVICE_ORDER}/${serviceOrderId}`)
      : dispatch(getStepStatusLog(serviceOrderId, budgetId))

    if (budget) {
      setIsRevised(budget.isRevised)
    }
  }, [budget])

  const handleCancelApproveClick = useCallback(() => {
    dispatch(cancelApproveBudget(serviceOrderId, budgetId)).then(() => {
      refetchServiceOrder()
      dispatch(getHistoryLogs(serviceOrderId))
      onToggleCancelApproveBudgetDrawerOpen()
      dispatch(getStepStatusLog(serviceOrderId, budgetId))
    })
  }, [budgetId, dispatch, serviceOrderId])

  const handleSetRevisedBudget = useCallback(() => {
    setIsRevised(true)
  }, [])

  const handleSetCancelReviseBudget = useCallback(() => {
    setIsRevised(false)
  }, [])

  const buttons = useMemo(
    () => [
      {
        id: 'reviewBudget',
        showButton: canReviewBudget && (isAdmin || isSAAS),
        label: 'Marcar como revisado',
        onClick: () =>
          checkUserPermission(
            PERMISSIONS.REVIEW_RETURN_BUDGET_CANCEL_ACTION,
            PERMISSIONS_ACTIONS.ADD,
            handleMarkAsRevisedClick
          ),
        color: 'primary',
        variant: 'contained',
      },
      {
        id: 'intermediaryReviewBudget',
        showButton: canIntermediaryReviewBudget,
        label: 'Validar orçamento',
        onClick: () =>
          checkUserPermission(
            PERMISSIONS.VALIDATE_INVALIDATE_BUDGET_CANCEL_ACTION,
            PERMISSIONS_ACTIONS.ADD,
            handleMarkAsRevisedByIntermediaryClick
          ),
        color: 'primary',
        variant: 'contained',
      },
      {
        id: 'intermediaryRefuseBudget',
        showButton: canIntermediaryRefuseBudget,
        label: 'Recusar orçamento',
        onClick: () =>
          checkUserPermission(
            PERMISSIONS.VALIDATE_INVALIDATE_BUDGET_CANCEL_ACTION,
            PERMISSIONS_ACTIONS.ADD,
            onToggleIntermediaryRefuseModalOpen
          ),
        variant: 'outlined',
        className: styles.cancelApproveButton,
      },
      {
        id: 'returnBudget',
        showButton: canReturnBudget,
        label: 'Devolver ao prestador',
        onClick: () =>
          checkUserPermission(
            PERMISSIONS.REVIEW_RETURN_BUDGET_CANCEL_ACTION,
            PERMISSIONS_ACTIONS.ADD,
            handleReturnToProviderClick
          ),
        color: 'primary',
        variant: 'outlined',
      },
      {
        id: 'reproveBudget',
        showButton: canReproveBudget,
        label: 'Recusar orçamento',
        onClick: onToggleRejectBudgetDrawerOpen,
        color: 'primary',
        className: styles.rejectButton,
      },
      {
        id: 'cancelReview',
        showButton: canCancelReview,
        label: 'Cancelar revisão',
        onClick: handleCancelRevisedClick,
        color: 'primary',
        variant: 'outlined',
      },
      {
        id: 'reproveInnerBudget',
        showButton: canReproveInnerBudget,
        label: 'Recusar orçamento interno',
        onClick: onToggleRejectBudgetDrawerOpen,
        color: 'primary',
        className: styles.rejectButton,
      },
      {
        id: 'cancelApproval',
        showButton: canCancelApproval,
        label: 'Cancelar aprovação',
        onClick: () =>
          checkUserPermission(
            PERMISSIONS.APPROVE_REJECT_BUDGET_CANCEL_ACTION,
            PERMISSIONS_ACTIONS.ADD,
            handleCancelApproval
          ),
        variant: 'outlined',
        className: styles.cancelApproveButton,
      },
      {
        id: 'approveBudget',
        showButton: canApproveBudget,
        label: 'Aprovar orçamento',
        onClick: () =>
          checkUserPermission(
            PERMISSIONS.APPROVE_REJECT_BUDGET_CANCEL_ACTION,
            PERMISSIONS_ACTIONS.ADD,
            handleApproveBudgetClick
          ),
        color: 'primary',
        variant: 'contained',
      },
      {
        id: 'approveInnerBudget',
        showButton: canApproveInnerBudget,
        label: 'Aprovar orçamento interno',
        onClick: handleApproveInnerBudgetClick,
        variant: 'contained',
        color: 'primary',
      },
    ],
    [
      canApproveBudget,
      canApproveInnerBudget,
      canCancelApproval,
      canCancelReview,
      canReproveBudget,
      canReproveInnerBudget,
      canReturnBudget,
      canReviewBudget,
      canIntermediaryReviewBudget,
      handleApproveBudgetClick,
      handleApproveInnerBudgetClick,
      handleCancelApproval,
      handleCancelRevisedClick,
      handleMarkAsRevisedClick,
      handleReturnToProviderClick,
      onToggleRejectBudgetDrawerOpen,
      styles.cancelApproveButton,
      styles.rejectButton,
    ]
  )

  const renderButtons = useMemo(() => {
    return (
      <Grid container gap={1}>
        {budget?.refused ? (
          <Grid item>
            <Button
              variant="outlined"
              className={styles.cancelRejectButton}
              onClick={() =>
                checkUserPermission(
                  PERMISSIONS.REVIEW_RETURN_BUDGET_CANCEL_ACTION,
                  PERMISSIONS_ACTIONS.ADD,
                  isActive && !isCanceled
                    ? onToggleUnrefuseBudgetDialogOpen
                    : handleServiceOrderCanceled
                )
              }
            >
              Cancelar Recusa
            </Button>
          </Grid>
        ) : (
          buttons
            .filter(button => button.showButton)
            // eslint-disable-next-line no-unused-vars
            .map(({ id, label, showButton, ...buttonProps }) => (
              <Grid key={id}>
                <Button {...buttonProps}>{label}</Button>
              </Grid>
            ))
        )}
      </Grid>
    )
  }, [budget?.get('refused'), buttons, styles.cancelRejectButton, budget?.get('approved')])

  if (!budget || budget?.isOriginal) {
    return null
  }

  return (
    <>
      <Divider className={styles.divider} />

      {dialogInfo?.message && <ConfirmRemoveDialog message={dialogInfo.message} />}

      {renderButtons}

      <RefuseBudgetDialog
        isOpen={isUnrefuseBudgetDialogOpen}
        onClose={onToggleUnrefuseBudgetDialogOpen}
        serviceOrderId={serviceOrderId}
        budgetId={budget?.id}
        hasAnotherAvailableBudget={hasAnotherAvailableBudget}
      />

      <SaveChangesDialog isOpen={isSaveChangesModalOpen} onClose={onToggleSaveChangesDialog} />

      <ReviseBudgetDialog
        isOpen={isReviseBudgetDialogOpen}
        setIsReviseBudgetDialogOpen={setIsReviseBudgetDialogOpen}
        serviceOrderId={serviceOrderId}
        budgetId={budget?.id}
        handleIsRevised={handleSetRevisedBudget}
      />

      <ReturnToProviderDialog
        isOpen={isReturnToProviderDialogOpen}
        closeModal={onToggleReturnToProviderDialog}
        serviceOrderId={serviceOrderId}
        budgetId={budget?.id}
      />

      <RejectBudgetDrawer
        isOpen={isRejectBudgetDrawerOpen}
        onClose={onToggleRejectBudgetDrawerOpen}
        cancelReasons={cancelReasons}
        isRevised={isRevised}
        setSavingBudget={setSavingBudget}
        isSavingLoading={isSavingLoading}
      />

      <CancelSentEmailRevisedBudget
        isOpen={isCancelSentEmailReviseBudgetOpen}
        onClose={onToggleCancelSentEmailReviseBudgetOpen}
        serviceOrderId={serviceOrderId}
        budgetId={budget?.id}
        handleCancelRevise={handleSetCancelReviseBudget}
      />

      <CancelRevisedBudgetDialog
        isOpen={isCancelReviseBudgetDialogOpen}
        onClose={onToggleCancelReviseBudgetDialog}
        serviceOrderId={serviceOrderId}
        budgetId={budget?.id}
      />

      {isCancelApproveBudgetDrawerOpen && (
        <CancelApproveModal
          handleModal={onToggleCancelApproveBudgetDrawerOpen}
          handleConfirm={handleCancelApproveClick}
        />
      )}

      {isUnavailableBudgetOpen && (
        <UnavailableActionBudgetModal
          title="Aprovar orçamento"
          text={unavailableBudgetText}
          handleModal={onToggleIsUnavailableBudgetOpen}
          handleConfirm={onToggleIsUnavailableBudgetOpen}
        />
      )}

      <IntermediaryRefuseModal
        open={isIntermediaryRefuseModalOpen}
        budgetId={budgetId}
        serviceOrderId={serviceOrderId}
        handleModal={onToggleIntermediaryRefuseModalOpen}
        handleRevised={handleSetCancelReviseBudget}
      />

      <ModalDialog modalDialog={modalDialog} setModalDialog={setModalDialog} />
    </>
  )
}

export default BudgetButtons
